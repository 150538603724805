import { CurrentOrganizationOutput, EventQuery } from "~graphql/sdk";

import _event from "./base-event.json";
import _release from "./base-release.json";
import _queryMe from "./graphql-query-me.json";
import _organization from "./organization.json";

const event = _event as EventQuery["event"];
const release = _release;
const queryMe = _queryMe;
const organization = _organization as CurrentOrganizationOutput;

export { event, release, queryMe, organization };
